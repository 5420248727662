<template>
    <div class="mod-config">
        <el-page-header @back="$emit('goBack', true)">
            <span slot="content" class="fwb">品牌价值测算详情</span>
        </el-page-header>
        <el-divider></el-divider>
        <el-card>
            <el-form :model="user" ref="user" style="margin-left: 15px;margin-right: 15px;" label-width="100px">
                <el-row :gutter="20" style="width: 100%">
                    <el-col :span="6" >
                        <el-form-item prop="enterpriseName" label="公司名称：">
                            <el-input v-model="user.enterpriseName" placeholder="公司名称" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brandName" label="品牌名称：">
                            <el-input v-model="user.brandName" placeholder="品牌名称" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="mobile" label="手机号：">
                            <el-input v-model="user.mobile" placeholder="手机号" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brand" label="附件：">
                            <a target="downloadFrame" download href="./static/template/valueData.xlsx" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-row :gutter="10">
            <el-col :span="8">
                <el-card style="margin-top: 10px;margin-bottom: 10px;">
                    <div id="histogram" :style="{width: '500px', height: '300px'}"></div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card style="margin-top: 10px;margin-bottom: 10px;">
                    <div id="line" :style="{width: '460px', height: '300px'}"></div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card style="margin-top: 10px;margin-bottom: 10px;">
                    <div id="radar " :style="{width: '460px', height: '300px'}"></div>
                </el-card>
            </el-col>
        </el-row>
        <el-card style="margin-top: 10px">
            <div class="f20 fwb tac mb-2 mt-2">推荐结果</div>
            <div class="list-box uf uf fwrap-w tal">
                <template v-if="sbrzServiceList.length > 0">
                    <div class="list" v-for="(item, index) in sbrzServiceList" :key="index">
                        <div @click="goDetail(item.id)">
                            <div class="pic-box" >
                                <img :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>
                            </div>
                            <div class="ctx-box">
                                <div class="f18 ctx-box-price">
                                    <span v-if="item.minPrice !== item.maxPrice && item.minPrice !== -1"><span class="f20">￥</span>{{(item.minPrice || 0).toFixed(2)}} - {{(item.maxPrice || 0).toFixed(2)}}</span>
                                    <span class="f20" v-else-if="item.minPrice === item.maxPrice && item.minPrice !== -1">
                      ￥{{(item.minPrice || 0).toFixed(2)}}
                    </span>
                                    <span v-else class="f20">面议</span>
                                    <span style="font-size: 5px; color: gray"></span>
                                </div>
                                <div class="dec ctx-link fwb">
                                    <span>{{ item.serviceName }}</span>
                                </div>
                                <div style="padding: 0 14px">
                                    <div class="f12">
                                        <span class="op-07">服务分类：{{ item.itemName }}</span>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="company mb-3 mt-1">
                                    <div class="cp uf uf-ac" style="font-size: 10px">
                                        <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                                            自营
                                        </div>
                                        <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                                            严选
                                        </div>
                                        <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                                            服务质保
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>
    import * as echarts from 'echarts'
    import {checkIsNull} from '../../../../utils'
    export default {
        props: {
            id: {
                type: String,
                default: ''
            }
        },
        components: {
        },
        data() {
            return {
                user: {
                    brandName: '',
                    enterpriseName: '',
                    mobile: ''
                },
                sbrzServiceList: [],
                histogramList: [],
                lineList: [],
                radarList: [],
                recommendService: '',
                mainId: '',
                corporatePortraitList: []
            }
        },
        computed: {
        },
        mounted () {
            this.getInfo()
        },
        methods: {
            getInfo () {
                this.dataListLoading = true
                this.$http({
                    url: this.$http.adornUrl(`${this.$api.TECH.BRAND.INFO}/${this.id}`),
                    method: 'get',
                    params: this.$http.adornParams({
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.user = data.data
                        this.histogramList = JSON.parse(data.data.assets)
                        this.lineList = JSON.parse(data.data.profit)
                        this.radarList.push(data.data.capitalPower)
                        this.radarList.push(data.data.marketPower)
                        this.radarList.push(data.data.innovationPower)
                        this.radarList.push(data.data.responsibilityPower)
                        this.histogram()
                        this.getResult()
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            getResult() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.RECOMMENDED.SUBMIT),
                    method: 'POST',
                    params: this.$http.adornParams({
                        mobile: this.user.mobile,
                        companyName: this.user.enterpriseName,
                    })
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        if (data.dataMap !== undefined) {
                            this.recommendService = data.dataMap.recommendService
                            this.mainId = data.dataMap.recommend.id
                            this.userInfoList = data.dataMap.userInfoList
                            this.getStats()
                        }
                    }
                })
            },
            // 推荐算法 进程
            getStats() {
                this.$http({
                    url: this.$http.adornUrl(this.$api.RECOMMENDED.STATS),
                    method: 'post',
                    params: this.$http.adornParams({
                        mobile: this.user.mobile
                    })
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        let mobile = this.user.mobile
                        let type = data[mobile]
                        if (type === 'CHECK_ING') {
                            this.getStats()
                        } else {
                            this.toResult()
                        }
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            // 推荐算法弹框
            toResult() {
                let list = [
                    this.user.enterpriseName,
                    this.user.mobile,
                    this.mainId,
                    this.user.enterpriseId
                ]
                this.corporatePortraitList = list
                this.getList()
            },
            // 获取企业画像接口
            getList() {
                let dataList = this.corporatePortraitList
                // if(dataList.length!== 0){
                //    dataList =  dataList.split(',')
                //
                // }else{
                //   dataList = []
                // }
                this.$http({
                    url: this.$http.adornUrl(this.$api.RECOMMENDED.LIST),
                    method: 'post',
                    params: this.$http.adornParams({
                        mobile: dataList.length > 0 ? dataList[1]: null,
                        mainId: dataList.length > 0 ? dataList[2]: null,
                        enterpriseId: dataList.length > 0 ? dataList[3]: null,
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let enterpriseLabel =data.dataList.enterpriseLabel.slice(0, 4)
                        let recommendService =data.dataList.recommendService
                        this.sbrzServiceList = recommendService
                        this.sbrzServiceList.forEach(item => {
                            if (checkIsNull(item.minPrice)) {
                                let min = item.skuList[0].price
                                item.skuList.forEach(sku => {
                                    if(sku.price < min){
                                        min = sku.price
                                    }
                                })
                                item.minPrice = min
                            }
                        })
                        this.companyLabels = []
                        enterpriseLabel.forEach((item, index)=>{
                            if (index < 4) {
                                this.companyLabels.push(item.labelName)
                            }
                        })
                        this.loading = false
                    } else {
                        this.$message.error(data.msg)
                        this.loading = false
                    }
                })
            },
            histogram () {
                let myChart1 = echarts.init(document.getElementById('histogram'), 'light')

                // 绘制图表
                myChart1.setOption({
                    title: {
                        text: '企业总资产',
                        left: 'left'
                    },
                    xAxis: {
                        data: ['2018', '2019', '2020']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    dataGroupId: '',
                    animationDurationUpdate: 500,
                    series: {
                        type: 'bar',
                        id: 'sales',
                        label: {
                            normal: {
                                show: true,
                                position: 'insideRight'
                            }
                        },
                        data: [
                            {
                                value: this.histogramList[0],
                                groupId: '2018'
                            },
                            {
                                value: this.histogramList[1],
                                groupId: '2019'
                            },
                            {
                                value: this.histogramList[2],
                                groupId: '2020'
                            }
                        ],
                        universalTransition: {
                            enabled: true,
                            divideShape: 'clone'
                        }
                    }
                })

                let myChart3 = echarts.init(document.getElementById('line'), 'light')
                myChart3.setOption({
                    title: {
                        text: '企业总利润走势曲线图'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['总利润']
                    },
                    grid: {
                        left: '10%',
                        right: '12%',
                        bottom: '10%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['2018', '2019', '2020']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '总利润',
                            type: 'line',
                            stack: 'Total',
                            data: this.lineList
                        }
                    ]
                })

                let myChart4 = echarts.init(document.getElementById('radar '), 'light')
                myChart4.setOption({
                    title: {
                        text: '企业价值力分布'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        left: 'right',
                        data: [
                            '价值'
                        ]
                    },
                    radar: [
                        {
                            indicator: [
                                { text: '资本力' },
                                { text: '市场力' },
                                { text: '创新力' },
                                { text: '责任力' }
                            ],
                            center: ['50%', '50%'],
                            radius: 100
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {},
                            data: [
                                {
                                    value:  this.radarList,
                                    name: '价值'
                                }
                            ]
                        }
                    ]
                })
            },
            goDetail(id) {
                this.$router.push({name: 'service', query: {id, isProvider: false}})
            }
        }
    }
</script>


<style scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }
</style>
