<template>
    <div class="mod-config">
        <template v-if="page === 'list'">
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(true)">
                <el-form-item>
                    <el-input v-model="dataForm.enterpriseName" placeholder="公司名称" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dataForm.brandName" placeholder="品牌名称" clearable></el-input>
                </el-form-item>
<!--                <el-form-item>-->
<!--                    <el-input v-model="dataForm.mobile" placeholder="手机号" clearable></el-input>-->
<!--                </el-form-item>-->
                <el-form-item>
                    <el-date-picker
                            v-model="dataForm.dateRange"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            unlink-panels
                            :clearable="false"
                            :pickerOptions="pickerOptions"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getDataList(true)">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                    :data="dataTableList"
                    border
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column
                        type="index"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="enterpriseName"
                        header-align="center"
                        align="center"
                        label="公司名称">
                </el-table-column>
                <el-table-column
                        prop="brandName"
                        header-align="center"
                        align="center"
                        label="品牌名称">
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        header-align="center"
                        align="center"
                        label="手机号">
                </el-table-column>
<!--                <el-table-column-->
<!--                        prop="brand"-->
<!--                        header-align="center"-->
<!--                        align="center"-->
<!--                        show-overflow-tooltip-->
<!--                        label="附件">-->
<!--                    <template slot-scope="scope">-->
<!--                        <a target="downloadFrame" download :href="scope.row.filePath" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="createTime"
                        header-align="center"
                        align="center"
                        label="报告生成时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="changePage('detail', scope.row)">查看详情</el-button>
                        <el-button type="text" size="small">
                            <el-link :href="'http://model.smartquality.cn/' + scope.row.reportPath" target="_blank" style="font-size: 12px">下载报告</el-link>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="tac mt-3"
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </template>
        <brand-value-detail ref="detailBrandValue" v-if="page === 'detail'" :id="this.id" @goBack="goBack"></brand-value-detail>
    </div>
</template>
<script>
    import moment from 'moment'
    import BrandValueDetail from './brand-value-detail'
    export default {
        components: {
            BrandValueDetail
        },
        data() {
            return {
                dataForm: {
                    enterpriseName: '',
                    brandName: '',
                    mobile: '',
                    dateRange: []
                },
                dataTableList: [],
                tagType: {1: '', 2: 'success', 3: 'danger'},
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                pickerOptions: {
                    shortcuts: [{
                        text: '本月',
                        onClick(picker) {
                            let begin = new Date()
                            begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
                            let end = new Date()
                            picker.$emit('pick', [begin, end])
                        }
                    }, {
                        text: '今年至今',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date(new Date().getFullYear(), 0)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
                            picker.$emit('pick', [start, end])
                        }
                    }]
                },
                page: 'list'
            }
        },
        created() {
            // 初始化查询时间范围
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            this.dataForm.dateRange = [start, end]
            this.getDataList(true)
        },
        methods: {
            // 获取数据列表
            getDataList(refresh) {
                this.dataListLoading = true
                if (refresh) {
                    this.pageIndex = 1
                }
                let [start, end] = this.dataForm.dateRange || []
                this.$http({
                    url: this.$http.adornUrl(this.$api.TECH.BRAND.LIST),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'enterpriseName': this.dataForm.enterpriseName || null,
                        'brandName': this.dataForm.brandName || null,
                        'mobile': this.dataForm.mobile || null,
                        'beginDate': start ? moment(start).format('YYYY-MM-DD') : null,
                        'endDate': end ? moment(end).format('YYYY-MM-DD') : null
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataTableList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataTableList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
                // this.dataTableList = [{
                //     brandName: '山科智心',
                //     enterpriseName: '山东山科智心科技有限公司',
                //     mobile: '13181732881',
                //     createTime: '2022-1-12-07',
                //     enclosure: './static/template/valueData.xlsx'
                // }]
                // this.dataListLoading = false
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList(true)
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList(false)
            },
            changePage(type, rowData) {
                this.page = type
                this.id = rowData.id
            },
            // 页面回调
            goBack(refresh) {
                this.page = 'list'
                if (refresh) {
                    this.getDataList(true)
                }
            },
            // 删除
            deleteHandle(id) {
                this.$confirm('确定进行删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$http.adornUrl(this.$api.ORDER.DELETE),
                        method: 'post',
                        params: this.$http.adornParams({
                            'id': id
                        })
                    }).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList(true)
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }

</script>
