import { render, staticRenderFns } from "./brand-value-detail.vue?vue&type=template&id=f7a95d60&scoped=true&"
import script from "./brand-value-detail.vue?vue&type=script&lang=js&"
export * from "./brand-value-detail.vue?vue&type=script&lang=js&"
import style0 from "./brand-value-detail.vue?vue&type=style&index=0&id=f7a95d60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7a95d60",
  null
  
)

export default component.exports